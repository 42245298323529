import React, { useState, useEffect } from 'react';

import { motion, useAnimate } from 'framer-motion';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import fonts from 'components/layout/fonts';
import { tw } from 'lib/utils';

import styles from './circle-score-fluid.module.scss';

import { LogoSvg } from './logo-svg';

type CircleScoreFluidSize = 'xs' | 'sm' | 'mid' | 'md' | 'lg' | 'xl' | '2xl';

export interface CircleScoreFluidProps {
  size?: CircleScoreFluidSize;
  amount: number;
  progress: number;
  className?: string;
  logoClassName?: string;
  scoreTextClassName?: string;
  scoreNumberClassName?: string;
  isStatic?: boolean;
  isStartFromZero?: boolean;
  origin: string;
}
export const CircleScoreFluid: React.FC<CircleScoreFluidProps> = ({
  size,
  progress,
  amount,
  className,
  logoClassName,
  scoreTextClassName,
  scoreNumberClassName,
  isStatic = false,
  isStartFromZero = false,
  ...props
}) => {
  const [displayAmount, setDisplayAmount] = useState(0);
  const [, animate] = useAnimate();

  useEffect(() => {
    if (isStatic) {
      return;
    }

    animate(0, amount, {
      onUpdate: (latest) => setDisplayAmount(latest),
      duration: 1,
      ease: 'easeInOut',
    });
  }, [isStatic, amount, animate]);

  return (
    <section
      className={tw(styles.score, 'relative max-w-full rounded-full bg-white', size ? styles[`score--${size}`] : undefined, styles[className!] || className)}
      {...props}
    >
      <CircleProgress size="95%" progress={progress} isStartFromZero={isStartFromZero} />
      <div className={styles.info}>
        <div className={styles['logo-text']}>
          <LogoSvg className={tw(styles.logo, logoClassName)} />
          <span className={tw(fonts.hankenGrotesk.className, scoreTextClassName)}>Score</span>
        </div>
        <motion.div className={tw(styles.amount, scoreNumberClassName)}>{displayAmount?.toFixed(1)}</motion.div>
      </div>
    </section>
  );
};
