import React from 'react';

import { ProductFeatures } from 'components/bloks/n4/product/product-scorecard/product-features';
import { IdealScenarios } from 'components/bloks/n4/product/product-scorecard/product-scorecard';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import PriceButton from 'components/widgets/price-button';
import { normalizeUrl, tw } from 'lib/utils';
import type { HearingAid, Model, Release } from 'types/release';

import styles from './product-info.module.scss';

interface DetailsTabProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  audio: React.JSX.Element | null | undefined;
  useModel: boolean;
  onTabClick: (tab: number) => void;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ hearingAid, model, release, audio, useModel, onTabClick }) => {
  const currentModel = release.models.find((m) => m.id === model?.id);
  const scores = (hearingAid && hearingAid.scores) || (useModel && model?.scores) || release.scores;

  return (
    <>
      <div className={tw('relative mt-[32px] flex w-full flex-col border-[#D4D4D4] pb-[0.5rem] md:w-[200px] md:pb-[24px] lg:w-[261px]')}>
        {scores?.total_score && (
          <CircleScoreFluid
            size="md"
            progress={((scores?.total_score || 0) / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
            amount={scores?.total_score || 0}
            className="absolute left-3 top-3"
            origin="directory/product-info/product-info-popup"
          />
        )}
        <ImageWrapper
          image={(useModel && model?.image) || release.image}
          origin="featured-articles"
          imgProps={{
            className: 'border border-navy/10 rounded-[20px] h-[221px] object-contain bg-white py-6 px-6',
          }}
        />
        <div className="mt-6">
          <PriceButton
            release={release}
            model={currentModel}
            prices={release.prices}
            noGeo
            origin="directory/product-info-popup"
            position="product-info-popup"
            onViewPrices={() => onTabClick(1)}
          />
        </div>
      </div>
      <div className={tw('overflow-y-scroll pb-[24px] md:w-[400px] lg:w-[440px] lg:pt-[32px]', styles['ht-product-info-scroll'])}>
        <div className="grid items-start pb-6">
          <div className="text-[32px] leading-[130%] tracking-[-0.32px] text-navy">
            <HtLink href={normalizeUrl({ url: release.path, origin: `product-info-popup#details-${release.slug}` })}>
              {(useModel ? model?.full_name : release.full_name) || release.name}
            </HtLink>
          </div>
          <div className="mt-[6px] text-[20.8px] leading-[200%] tracking-[-0.624px] text-lapis">
            <PriceButton
              release={release}
              model={currentModel}
              prices={release.prices}
              noGeo
              origin="directory/product-info-popup"
              position="product-info-popup"
              display="link"
              useFallback
              onViewPrices={() => onTabClick(1)}
            />
          </div>
          <div className={tw('mt-[12px] text-lg tracking-[-0.48px] text-navy/[0.80] md:text-[16px]', 'leading-[140%]')}>{release.description}</div>
          {audio && (
            <div className="mt-[16px] flex items-center gap-x-[15px]">
              <div>{audio}</div>
              <span className="text-[16px] leading-[140%] tracking-[-0.1px] text-navy">Listen to this device</span>
            </div>
          )}
        </div>
        {(useModel && currentModel?.tags?.length) || (!useModel && release.tags?.length) ? (
          <IdealScenarios
            tags={useModel ? currentModel?.tags || [] : release.tags}
            className="mt-0"
            titleClassName="tracking-tight text-navy text-lg"
            origin="product-popup"
          />
        ) : null}
        {model && <ProductFeatures release={release} className="border-t-[1px] border-[#D4D4D4] pb-0 pt-5" />}
      </div>
    </>
  );
};
